.Sitebar {
  overflow-y: auto;
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  color: white;
  background-color: #4b91b3;
}

.Sitebar__logo {
  margin-top: 0;
  margin-bottom: 5px;
  font-family: 'Caveat', cursive;
  font-size: 28px;
  font-weight: 100;
}

.Sitebar__subTitle {
  margin: 0 0 10px;
  color: white;
  font-size: 14px;
}

.Sitebar__sectionHeading {
  margin-bottom: 10px;
}

.Sitebar__button {
  margin: 5px 2px;
}

.Sitebar > hr {
  margin: 20px 0;
  opacity: 0.3;
}

.Sitebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Sitebar li:first-child {
  margin-bottom: 10px;
}
