.button {
  display: inline-block;
  padding: 2px 5px;
  cursor: default;
  vertical-align: middle;
  text-decoration: none;
  color: white;
  border: 0;
  border-radius: 0px;
  outline: 0;
  background: rgba(255,255,255,0.1);
  font-size: 12px;
  line-height: 21px;
}

.button:hover {
  background-color: rgba(255,255,255,0.15);
}

.button:active {
  background-color: rgba(255,255,255,0.05);
}

.button--link {
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
}