.Dom {
  position: relative;
  overflow: hidden;
}

.Dom__boxes {
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
}

.Dom__boxReorderButtons > button {
  width: 50%;
  padding: 5px;
  border-radius: 0;
}

.Dom__boxReorderButton--isDisabled {
  pointer-events: none;
  opacity: 0.05;
}
