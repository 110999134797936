.DomBox {
  position: relative;
  overflow: hidden;
  padding: 5px 75px 5px 20px;
  cursor: default;
  white-space: nowrap;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  font-family: monospace;
  color: rgba(255,255,255,0.5);
}

.DomBox:hover {
  background-color: rgba(255,255,255,0.05);
  color: rgba(255,255,255,0.8);
}

.DomBox--isActive {
  background-color: #4B91B3 !important;
  color: #fff !important;
}

.DomBox__id {
  position: absolute;
  top: 5px;
  left: 5px;
  color: rgba(255,255,255,0.1);
}

.DomBox--isActive .DomBox__id {
  color: rgba(255,255,255,0.6);
}

.DomBox__indenter {
  color: rgba(255,255,255,0.1);
}

.DomBox--isActive .DomBox__indenter {
  color: transparent;
}

.DomBox__titleInput {
  border: 0;
  outline: 0;
  padding: 1px 5px;
  display: block;
  position: absolute;
  top: 5px;
  left: 25px;
}

.DomBox__buttons {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.DomBox__button {
  display: inline-block;
  width: 25px;
  height: 100%;
  margin: 0;
  padding: 2px 4px;
  cursor: default;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  color: rgba(255,255,255,0.1);
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 14px;
}

.DomBox__button:hover {
  color: white !important;
  background-color: rgba(255,255,255,0.1);
}

.DomBox--isActive .DomBox__button {
  color: rgba(255,255,255,0.8);
}

.DomBox--isActive .DomBox__button:hover {
  color: rgba(255,255,255,1) !important;
}

.DomBox:hover .DomBox__button {
  color: rgba(255,255,255,0.6);
}

.DomBox:hover.DomBox--isActive .DomBox__button {
  color: rgba(255,255,255,0.8);
}
