* {
  box-sizing: border-box;
}

html,
body,
#root {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
  font-size: 14px;
  background-color: #252831;
}

.hidden {
  display: none;
}
