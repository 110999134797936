* {
  box-sizing: border-box;
}

html,
body,
#root {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
  font-size: 14px;
  background-color: #252831;
}

.hidden {
  display: none;
}

.App {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.App__fullPageWarning {
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  background-color: #252831;
  transition: transform 300ms ease;
  text-align: center
}

.App__fullPageWarning > div {
  max-width: 300px;
}

.App__fullPageWarning p {
  text-align: justify;
  line-height: 1.3;
}

.App__fullPageWarning i {
  color: #4b91b3;
}

.App__fullPageWarning button {
  padding: 0;
  cursor: pointer;
  color: #4b91b3;
  border-width: 0;
  outline: none;
  background-color: transparent;
  font-size: inherit;
}

@media(min-width: 1024px) {
  .App__screenTooSmall {
    display: none;
  }
}

.App__screenTooSmall--isHidden {
  transform: translate(-100%, 0);
}

.App__browserWarning {
  z-index: 9;
}

.Resizer {
  z-index: 1;
  box-sizing: border-box;
  opacity: 0.2;
  background: #000000;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  width: 100%;
  height: 5px;
  margin: -2px 0;
  cursor: row-resize;
  border-top: 2px solid rgba(255, 255, 255, 0);
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}

.Resizer.horizontal:hover {
  border-top: 2px solid rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 5px;
  margin: 0 -2px;
  cursor: col-resize;
  border-right: 2px solid rgba(255, 255, 255, 0);
  border-left: 2px solid rgba(255, 255, 255, 0);
}

.Resizer.vertical:hover {
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  border-left: 2px solid rgba(0, 0, 0, 0.5);
}
Resizer.disabled {
  cursor: not-allowed;
}
Resizer.disabled:hover {
  border-color: transparent;
}

.button {
  display: inline-block;
  padding: 2px 5px;
  cursor: default;
  vertical-align: middle;
  text-decoration: none;
  color: white;
  border: 0;
  border-radius: 0px;
  outline: 0;
  background: rgba(255,255,255,0.1);
  font-size: 12px;
  line-height: 21px;
}

.button:hover {
  background-color: rgba(255,255,255,0.15);
}

.button:active {
  background-color: rgba(255,255,255,0.05);
}

.button--link {
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
}
.Pane {
  display: flex;
  flex-direction: column;
}

.Pane__component {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: white;
  overflow: hidden;
}

.Pane__title {
  margin: 0;
  padding: 5px 10px;
  background-color: rgba(255,255,255,0.05);
  font-size: 16px;
  line-height: 25px;
}

.Pane__titleButton {
  float: right;
}
.FBox {
  position: relative;
  display: flex;
  padding: 4px;
  transition: all 100ms ease-in-out;
  color: white;
  background-color: rgba(255,255,255,0.05);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  min-height: 40px;
  min-width: 40px;
}

.FBox.isActive {
  color: #ffffff;
  background-color: #4b91b3 !important;
}

.FBox.isRootBox {
  overflow: hidden;
  flex-grow: 1 !important;
  box-shadow: none;
  background-color: #252831;
}

.FBox.isActive > .FBox__id {
  opacity: 1;
}

.FBox.isActive .FBox {
  background: #252831;
}

.FBox__label {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 37px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 8px;
  color: rgba(255,255,255,0.6)
}
.Toolbar {
  position: relative;
  overflow: hidden;
}

.Toolbar__scroller {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
}

.Toolbar__intro {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 100%;
  transition: opacity 100ms ease;
  pointer-events: none;
  opacity: 1;
}

.Toolbar--isOpen .Toolbar__intro {
  transition: none;
  opacity: 0;
}

.Toolbar__groupTitle {
  margin: 10px 10px 10px;
  transition: all 100ms ease;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #aaaaaa;
  font-size: 16px;
}

.Toolbar__groupTitle:last-of-type {
  margin-top: 40px;
}

.Toolbar__groupTitle.Toolbar__disabled {
  color: #0b0c0f;
  border-color: #0b0c0f;
}

.Toolbar__section {
  margin-bottom: 20px;
  padding: 5px;
  text-align: center;
}

.Toolbar__splitSection {
  display: flex;
  justify-content: space-around;
}

.Toolbar h3,
.Toolbar h4 {
  margin-top: 0;
  margin-bottom: 5px;
  transition: all 100ms ease;
  text-align: center;
  text-transform: uppercase;
}

.Toolbar h3.Toolbar__disabled,
.Toolbar h4.Toolbar__disabled {
  color: #0b0c0f;
}

.Toolbar h3 {
  font-size: 14px;
}

.Toolbar h4 {
  font-size: 12px;
}

.Toolbar__nudgeButton {
  width: 30px;
  height: 30px;
  padding: 0;
  transition: all 100ms ease;
  vertical-align: middle;
  color: white;
  border: 3px solid #0b0c0f;
  outline: none;
  background: transparent;
  font-size: 20px;
}

.Toolbar__nudgeButton:hover {
  border-color: #ffffff;
}

.Toolbar__nudgeButton:active {
  color: #4b91b3;
  border-color: #4b91b3;
}

.Toolbar__nudgeText {
  display: inline-block;
  min-width: 25px;
  height: 30px;
  line-height: 30px;
}

.Toolbar__basisInput {
  width: 40px;
  height: 30px;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: baseline;
  color: #ffffff;
  border: 3px solid #0b0c0f;
  outline: none;
  background: transparent;
}

.Toolbar__basisInput:focus {
  border-color: #ffffff;
}

.Toolbar__iconRadioLabel {
  display: inline-block;
}

.Toolbar__iconRadioLabel > input {
  display: none;
}

/* Flexbox Icons */

.fbi {
  display: block;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Toolbar--isColumn .fbi-jc .fbi-items,
.Toolbar--isColumn .fbi-ai .fbi-items {
  flex-direction: column;
}

.fbi-items {
  display: flex;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  padding: 2px;
  transition: all 100ms ease;
  border: 3px solid #0b0c0f;
}

.Toolbar__iconRadioLabel:hover .fbi > .fbi-items {
  border-color: white;
}

.Toolbar__iconRadioLabel > input:checked + .fbi > .fbi-items {
  border-color: #4b91b3;
  background-color: #4b91b3;
}

.Toolbar__iconRadioLabel > input:checked + .fbi > .fbi-items > i {
  background-color: #ffffff;
}

.Toolbar__iconRadioLabel > input:disabled + .fbi > .fbi-items {
  border-color: #0b0c0f;
  background-color: transparent;
}

.Toolbar__iconRadioLabel > input:disabled + .fbi > .fbi-items > i {
  background-color: #0b0c0f;
}

.fbi-items > i {
  display: block;
  min-width: 4px;
  min-height: 4px;
  margin: 1px;
  transition: all 100ms ease;
  background-color: #999999;
}

.fbi > span {
  display: block;
  margin-top: 1px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 9px;
  font-style: normal;
}

.Toolbar__default {
  position: relative;
}

.Toolbar__default:after {
  position: absolute;
  bottom: -11px;
  left: 0;
  width: 100%;
  content: 'DEFAULT';
  color: #0b0c0f;
  font-size: 8px;
  line-height: 8px;
}

.Toolbar__iconRadioLabel > input:disabled + .fbi > span,
.Toolbar__iconRadioLabel > input:disabled + .fbi > .Toolbar__default:after {
  color: #0b0c0f;
}

.fbi-column > .fbi-items {
  flex-direction: column;
}

.fbi-wrap > .fbi-items {
  flex-wrap: wrap;
}

.fbi-jc-flex-end > .fbi-items {
  justify-content: flex-end;
}

.fbi-jc-center > .fbi-items {
  justify-content: center;
}

.fbi-jc-space-between > .fbi-items {
  justify-content: space-between;
}

.fbi-jc-space-around > .fbi-items {
  justify-content: space-around;
}

.fbi-ai-stretch > .fbi-items {
  align-items: stretch;
}

.fbi-ai-center > .fbi-items {
  align-items: center;
}

.fbi-ai-flex-start > .fbi-items {
  align-items: flex-start;
}

.fbi-ai-flex-end > .fbi-items {
  align-items: flex-end;
}

.fbi-ai-baseline > .fbi-items {
  align-items: baseline;
}

.fbi-ai-baseline > .fbi-items > i {
  padding: 1px;
  text-align: center;
  color: #252831;
  font-size: 6px;
  font-weight: bold;
  font-style: normal;
}

.fbi-ai-baseline > .fbi-items > i:last-child {
  font-size: 9px;
}

.fbi-as-auto > .fbi-items {
  align-items: center;
  justify-content: center;
}

.fbi-as-auto > .fbi-items > i {
  align-self: auto;
  background-color: transparent;
  font-style: normal;
}

.Toolbar__iconRadioLabel > input:checked + .fbi-as-auto > .fbi-items > i {
  background-color: transparent;
}

.fbi-as-stretch > .fbi-items > i {
  align-self: stretch;
}

.fbi-as-center > .fbi-items > i {
  align-self: center;
}

.fbi-as-flex-start > .fbi-items > i {
  align-self: flex-start;
}

.fbi-as-flex-end > .fbi-items > i {
  align-self: flex-end;
}

.Html pre {
  margin: 0 !important;
  background-color: transparent !important;
}

.Css pre {
  margin: 0 !important;
  background-color: transparent !important;
}

.DomBox {
  position: relative;
  overflow: hidden;
  padding: 5px 75px 5px 20px;
  cursor: default;
  white-space: nowrap;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  font-family: monospace;
  color: rgba(255,255,255,0.5);
}

.DomBox:hover {
  background-color: rgba(255,255,255,0.05);
  color: rgba(255,255,255,0.8);
}

.DomBox--isActive {
  background-color: #4B91B3 !important;
  color: #fff !important;
}

.DomBox__id {
  position: absolute;
  top: 5px;
  left: 5px;
  color: rgba(255,255,255,0.1);
}

.DomBox--isActive .DomBox__id {
  color: rgba(255,255,255,0.6);
}

.DomBox__indenter {
  color: rgba(255,255,255,0.1);
}

.DomBox--isActive .DomBox__indenter {
  color: transparent;
}

.DomBox__titleInput {
  border: 0;
  outline: 0;
  padding: 1px 5px;
  display: block;
  position: absolute;
  top: 5px;
  left: 25px;
}

.DomBox__buttons {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.DomBox__button {
  display: inline-block;
  width: 25px;
  height: 100%;
  margin: 0;
  padding: 2px 4px;
  cursor: default;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  color: rgba(255,255,255,0.1);
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 14px;
}

.DomBox__button:hover {
  color: white !important;
  background-color: rgba(255,255,255,0.1);
}

.DomBox--isActive .DomBox__button {
  color: rgba(255,255,255,0.8);
}

.DomBox--isActive .DomBox__button:hover {
  color: rgba(255,255,255,1) !important;
}

.DomBox:hover .DomBox__button {
  color: rgba(255,255,255,0.6);
}

.DomBox:hover.DomBox--isActive .DomBox__button {
  color: rgba(255,255,255,0.8);
}

.Dom {
  position: relative;
  overflow: hidden;
}

.Dom__boxes {
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
}

.Dom__boxReorderButtons > button {
  width: 50%;
  padding: 5px;
  border-radius: 0;
}

.Dom__boxReorderButton--isDisabled {
  pointer-events: none;
  opacity: 0.05;
}

.Sitebar {
  overflow-y: auto;
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  color: white;
  background-color: #4b91b3;
}

.Sitebar__logo {
  margin-top: 0;
  margin-bottom: 5px;
  font-family: 'Caveat', cursive;
  font-size: 28px;
  font-weight: 100;
}

.Sitebar__subTitle {
  margin: 0 0 10px;
  color: white;
  font-size: 14px;
}

.Sitebar__sectionHeading {
  margin-bottom: 10px;
}

.Sitebar__button {
  margin: 5px 2px;
}

.Sitebar > hr {
  margin: 20px 0;
  opacity: 0.3;
}

.Sitebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Sitebar li:first-child {
  margin-bottom: 10px;
}

