.FBox {
  position: relative;
  display: flex;
  padding: 4px;
  transition: all 100ms ease-in-out;
  color: white;
  background-color: rgba(255,255,255,0.05);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  min-height: 40px;
  min-width: 40px;
}

.FBox.isActive {
  color: #ffffff;
  background-color: #4b91b3 !important;
}

.FBox.isRootBox {
  overflow: hidden;
  flex-grow: 1 !important;
  box-shadow: none;
  background-color: #252831;
}

.FBox.isActive > .FBox__id {
  opacity: 1;
}

.FBox.isActive .FBox {
  background: #252831;
}

.FBox__label {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 37px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 8px;
  color: rgba(255,255,255,0.6)
}