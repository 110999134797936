.Pane {
  display: flex;
  flex-direction: column;
}

.Pane__component {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: white;
  overflow: hidden;
}

.Pane__title {
  margin: 0;
  padding: 5px 10px;
  background-color: rgba(255,255,255,0.05);
  font-size: 16px;
  line-height: 25px;
}

.Pane__titleButton {
  float: right;
}